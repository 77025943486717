// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px

}

.topBarImageAlignment {
    text-align: center;
}
body a:hover {
    color: $color-template;
}
.theme-light .form input, .theme-light .form textarea, .theme-light .topbar__link-icon {
    color: black;
}
.themeColorText {
    color: $color-template;
}
.paginationcss{
    margin-right: 0;
}
.topbarMenu{
    width: 200px;
    border-radius: 0;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
} 
.theme-white{
    // border: 1px solid #ddd !important;
    background: white !important
}
.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
}


// topbar menu styles end
.form input {
    background :white;
    margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-operator {
    display: none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
    display: none;
}
.p-column-filter-buttonbar .p-button:not(.p-button-icon-only) {
    width: 100%
}
.p-column-filter-menu-button, .p-column-filter-clear-button {
    color : white
}
body .p-datatable .p-datatable-tbody > tr.p-highlightHoliday {
    background-color: #fbff2b;
}
body .p-datatable .p-datatable-tbody > tr.p-highlightWeekend {
    background-color: #1efd00;
}
body .p-datatable .p-datatable-tbody > tr.p-highlightLessThan6Hrs {
    background-color: #ff0000;
}
body .p-datatable .p-sortable-column .p-sortable-column-icon { 
    color:$color-white;
}
.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    font-size: 15px;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active {
    background: $button-text-color;
    color: $button-bg-color;
}
body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width:2em;
    height: 32px;
}
body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}
body .p-button {
    background-color: $button-bg-color;
    border-color: $button-bg-color;
    color: $button-text-color;
    padding: 8px;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        background-color: $button-bg-color;
        border-color: $button-hover-color !important;
        color: $button-text-color !important;
    }
}
.p-button:enabled:hover,.p-button:enabled:active {
    background-color: $button-hover-color;
    border-color: $button-hover-color ;
    color: $button-text-color;
}

.p-button.p-button-outlined {
    color: $button-bg-color;
}

.p-button.button-filter {
    padding: 8px 14px;
    font-size: small;
    height: 42px;
}

.p-button-sm.button-filter{
    padding: 15px;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open {
    background: $button-bg-color;
    border-color: $button-bg-color;
    color: $button-text-color;
}

//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px;
}
.p-autocomplete-token.p-highlight{
    margin-bottom: 2px;
}
 // Dasboard 
.dashBoardCardWithScroll {
    height: 390px;
}

.p-column-title{
  text-transform: capitalize !important;
  font-size: 16px !important;
  }

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}
.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}
.table-fixed tbody td,
.table-fixed thead > tr > th {
    float: left;
}
//kpiTable
.kpiTable {
    background-color: $color-template;
    color: black;
}

.colorLegend {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

body .p-multiselect-panel .p-multiselect-items{
    color: black
}

// body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
//     background-color:$color-template;
    
// }
body .p-checkbox .p-checkbox-box.p-highlight{
    background-color: $color-template;
    border-color: $color-template
}
body .p-radiobutton .p-radiobutton-box.p-highlight {
    background-color: $color-template !important;
    border-color: $color-template !important;
}

body .p-input-overlay-visible{
    z-index: 9999 !important;
}

.color-picker__popover{
    z-index: 9999;
}

.calendar .rbc-off-range-bg {
    background-color: #e5e5e5;
}

.textElipses {
    font-weight: bold;
    color: $color-template;
    cursor: pointer;

    >a>img {
        width: 40px;
        height: 20px;
        margin: 5px;
    }
}
// .p-dropdown .p-dropdown-trigger {
//     height: 100%;
// }
// .css-vj8t7z{
//     min-height: 0 !important
// }
// .css-1hwfws3{
//     position: static !important;
// }
// .css-10nd86i react-select{
//     height: 33px
// }
// .css-2o5izw .react-select__control .react-select__control--is-focused{
//     height: 33px;
// }

.botmessagesText{
    padding:5px;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 8px;
    padding-left: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: 5px;
    align-self: flex-start;
    width:90%;
    border-color: red
}
.usermessagesText {
    background-color: #f0f1ef;
    padding-top: 5px;
    padding-left: 7px;
    margin-right: 5px;
    margin-top: 7px; 
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 90%;
    text-align: left
}
.p-multiselect-label-container{
    height: 30px;
}

.p-autocomplete-input-token input{
    border:none
}

.logout_modal_width{
    max-width: 400px;
}

.call_action_column{
    text-align: 'center';
    text-decoration:underline;
    color:$color-template;
    font-weight: bold;
    cursor: pointer;
}

.p-datatable-row{
    cursor: initial !important;
}
.p-datatable-row:hover{
    background: transparent
}
.settings_form{
    width: 23%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: solid 1px lightgray;
}
.form .settings_form:focus, .form .settings_form:active{
    outline: none;
    border-color: #ffcd08;
}

.pending_badge,.started_badge{
    padding: 5px;
    font-size: 14px;
    margin-bottom: 2px;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
}
.vertical_middle {
    justify-content: center;
    align-items: center;
    display: flex;
}
body .p-component {
    font-family: unset;
}
.pagenumber-indicator{
    display: flex;
    height: 100%;
    align-items: center;
}
.rdw-editor-toolbar{
    img{
        height: 20px;
        width: 20px;
    }
}
.p-column-header-content:has(.p-checkbox){
    justify-content: center;
}
.p-filter-column{
    text-align: center !important;
    padding: 0.40rem !important;
    .p-inputtext{
        padding:0.45rem 0.75rem;
    }
    .p-column-filter-clear-button{
        display: none;
    }
    .p-multiselect-label {
        padding:0.25rem 0.75rem;
        color: black;
    }
    
}
.p-autocomplete input{
    width:100%;
}

.p-button{
    &:hover{
        background: $color-template !important;
        border-color: $color-template
    
    }
}
.ck-content{
    min-height: 30vh;
}

// .p-button.p-button-icon-only{
//     height: 3.2rem;
// }

.p-autocomplete-multiple-container{
    width: 100% !important;
}

.p-resizable-column {
    padding: 5px !important;
    font-weight: 600 !important;
    font-size: 15px !important;
}

//file upload css
.p-fileupload-file-badge {
    display: none !important;
}

.p-fileupload .p-fileupload-content {
    padding: 0px !important;
}

.p-message-icon {
    display: none;
}

.p-message-wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
    max-width: 100%;
    overflow: auto;
}

.p-message-summary,
.p-message-detail {
    grid-column: 2;
}

.p-message-close {
    grid-column: 1;
    top: -45px;
}

//css for buttons in the login component 
.scale {
    transform: scale(0.80);

    button {
        white-space: nowrap;
    }
}

.text-info {
    color: rgb(47, 39, 131) !important;
}

.login-container .login4 .card .card-body img {
    width: 250px;
    height: 250px;
}

.p-dialog-header {
    background-color: $table-header-bg-color !important;
    color: $table-header-text-color !important;
    height: 4rem !important;
    text-align: left;
    padding: 0.5rem;
}

.p-dialog .p-dialog-header {
    padding: 1rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: $table-header-text-color !important ;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: none;
    border-color: transparent;
}

.p-dialog .p-dialog-content {
    padding: 0 1rem .5rem 1rem;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
    height: 100%;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
    height: 100%;
}


.ResizePanel-module_ResizeContentHorizontal__1gGbA {
    max-width: 100%;
}

.p-button.p-fileupload-choose {
    padding: 0.75rem;
}

//css for auto complete field dropdown panel if options having long text
.p-autocomplete-panel .p-autocomplete-item {
    white-space: normal;
    word-wrap: break-word;
    padding: 0.50rem 1rem !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    font-family: $font-type;
}

.textElipses>a>img {
    width: 40px;
    height: 20px;
    margin: 5px;
}

.p-dialog .p-dialog-header .p-dialog-title {
    text-transform: capitalize;
    font-size: 1.15rem;
}

.inline-multi-select .p-multiselect .p-multiselect-label {
    color: rgb(64, 56, 56);
}

.chat-body {
    border-radius: 8px;
    padding: 10px;
    max-width: 70%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.panel .slidedown .glyphicon,
.chat .glyphicon {
    margin-right: 5px;
}

.panel-body {
    overflow-y: auto;
    max-height: 50rem;
}

.primary-font {
    color: $color-template;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 5%;
}

.form-col-2 {
    flex-basis: 15%;
}

.form-col-10 {
    flex-basis: 80%;
}

.form-col-12 {
    flex-basis: 100%;
}

.fixed-btn-height {
    height: 40px;
    margin-top: 30px;
}

.number_to_words {
    font-size: 12px;
    color: gray;
    text-transform: capitalize;
}

/* For screens larger than 1700px, hide the small header */
@media screen and (min-width: 1600px) {
    .screen-header-small {
        display: none;
    }
}

/* For screens smaller than 1700px, hide the large header */
@media screen and (max-width: 1600px) {
    .screen-header-large {
        display: none;
    }
}

//css for action buttons and pagination for screen headers
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    min-width: 2rem;
    width: 2.3rem;
    height: 2.3rem;
    margin: 0.12rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2.3rem;
    height: 2.4rem;
    margin: 0.12rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-paginator .p-dropdown {
    height: 2.5rem;
}

.p-button.p-button-sm {
    padding: 0.40rem 0.95rem !important;
}

.custom-Button {
    .p-button.p-button-icon-only {
        height: 2.75rem;
    }
}

//css changes for table headers
.p-column-filter-menu {
    .pi-filter-icon {
        visibility: hidden;
        opacity: 0;
    }
}

.p-sortable-column:hover {
    .pi-filter-icon {
        color: #343a40;
    }
}

.p-sortable-column:hover,
.p-resizable-column:hover,
.p-column-filter-menu-button-open,
.p-column-filter-menu-button-active {
    .pi-filter-icon {
        visibility: visible;
        opacity: 1;
    }
}

.p-sortable-column .p-sortable-column-icon {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s ease-in-out;
}

.p-sortable-column:hover .p-sortable-column-icon {
    visibility: visible;
    opacity: 1;
}

.p-sortable-column.p-highlight .p-sortable-column-icon {
    visibility: visible;
    opacity: 1;
    transition: none;
}

//scroll bar css for activities
.scrollable-description {
    max-height: 40rem;
    overflow-y: auto;
    padding: 5px;
    
    scrollbar-width: thin;
    scrollbar-color: #888 #f5f5f5;
}

.scrollable-description::-webkit-scrollbar {
    width: 6px;
}

.scrollable-description::-webkit-scrollbar-thumb {
    background: #c5c1c1;
    border-radius: 4px;
}

.scrollable-description::-webkit-scrollbar-track {
    background: #f5f5f5;
}

.custom-pt-20 {
    padding-top: 20px !important;
}